/**
 * <PRE>
 * System Name : 신태블릿 영업지원 시스템 (TSS)
 * Business Name : 공통 업무 유틸
 * Class Name : ServiceManager.js
 * Description :
 * 전자청약 서비스 호출 공통 유틸
 * </PRE>
 * @version 1.0
 * @author Copyright (C) 2010 by SAMSUNG SDS co.,Ltd. All right reserved.
 */
let store
const serviceManager = {}
const getStore = function () {
  store = window.vue.getStore('spStore')
  // 200109 getStorageStore 호출시점 변경
  // getStorageStore()
}
getStore()

/************************************************************************************************
  * Function명  : invokeServerDate
  * 설명        : 서버 날자를 호출
  ************************************************************************************************/
serviceManager.invokeServerDate = function (srnId) {
  let context = window.vue.getInstance('vue')
  context.$options.screenId = srnId
  return new Promise(function (resolve, reject) {
    window.vue.getInstance('http').post(context, {}, 'txTSSBC13S9', 'S').then(function (response) {
      if (response) {
        if (response.body !== null && response.body.svrDtm !== null) {
          store.dispatch('SERVER_DATE_SAVE', response.body.svrDtm)
          resolve(response.body.svrDtm)
        } else {
          store.dispatch('SERVER_DATE_SAVE', '')
          reject(response.msgComm)
        } // end else if
      } else {
        store.dispatch('SERVER_DATE_SAVE', '')
      } // end else if
    }).catch(function (error) {
      reject(error)
    })
  })
}

/************************************************************************************************
 * Function명  :setCommServiceData
 * 설명        : 서비스 호출 공통
 * @param parameter : 조회 조건
 ************************************************************************************************/
serviceManager.setCommServiceData = function (parameter, tagetId = 'txTSSBC16P1') {
  let pParams = {}
  pParams.appId = parameter.appId // 업무 구분
  // 오퍼레이션 코드와 신태블릿(TSS) 권한 체크 매핑 처리
  if ('P|K|A|N'.indexOf(parameter.fnctScCd) > -1) { // 유효성 검증, 계산, 결재/승인
    pParams.btnId = 'S' // 처리 권한
  } else if ('M|E|T'.indexOf(parameter.fnctScCd) > -1) { // 이메일, 문자메세지, 출력
    pParams.btnId = 'T' // 출력 권한
  } else if ('S|I|U|D'.indexOf(parameter.fnctScCd) > -1) { // 조회, 입력, 수정, 삭제
    pParams.btnId = parameter.fnctScCd // 설정 오퍼레이션 사용
  } else { // 그외 모두 조회 권한으로 처리
    pParams.btnId = 'S' // 조회권한
  } // end else if
  pParams.context = {} // Vue(this) 객체
  pParams.data = {} // 조회 Body
  // 권한 체크 하기 위해 screenId 값 세팅 여부 확인
  if (parameter.context) { // 화면 전달 객체 확인
    pParams.context = parameter.context // Vue(this) 객체
    if (!pParams.context.$options.hasOwnProperty('screenId') || !pParams.context.$options.screenId) {
      pParams.context.$options.screenId = parameter.srnId // 화면 ID 설정
    } // end else if
  } else {
    pParams.context = window.vue.getInstance('vue') // 기본 Vue(this) 객체 생성
    pParams.context.$options.screenId = parameter.srnId // 화면 ID 설정
    // page 처리 하기 위해
    if (parameter.hasOwnProperty('stndKeyList')) {
      pParams.context.$data.stndKeyList = parameter.stndKeyList // 화면 ID 설정
    } // end if
    if (parameter.hasOwnProperty('pageRowCnt')) {
      pParams.context.$data.pageRowCnt = parameter.pageRowCnt // 화면 ID 설정
    } // end if
  } // end else if

  if (pParams.appId === 'tss.ps') { // 신태블릿 직접 연계 일 때
    pParams.tagetId = parameter.trnstId
    pParams.data = parameter.data
  } else { // EAI 또는 LEGACY 연계 일 때
    pParams.tagetId = tagetId
    let commHeader = {}
    commHeader.requestSystemCode = 'F1339' // 요청 시스템 코드
    commHeader.fahrTrnId = !parameter.trnstId ? '' : parameter.trnstId // Trnst Id
    commHeader.eaiId = !parameter.serviceId ? '' : parameter.serviceId // EAI 연계 서비스
    commHeader.targetSystemCode = !parameter.appId ? '' : parameter.appId // LEGACY 응답 시스템 ID (sli.dc,sli.ch,sli.pi)
    commHeader.reqSrvcNm = !parameter.reqSrvcNm ? '' : parameter.reqSrvcNm // LEGACY 응답 서비스 Impl 명
    commHeader.reqMthdNm = !parameter.reqMthdNm ? '' : parameter.reqMthdNm // LEGACY 응답 서비스 함수 명
    commHeader.reqVONm = parameter.reqVONm // 요청 VO (Package 경로 + . + VO 명)
    commHeader.resVONm = parameter.resVONm // 응답 VO (Package 경로 + . + VO 명)
    parameter.common = commHeader
    if (tagetId === 'txTSSPS00S1') { // 청약접수 신청 일때 또는 별도 처리
      parameter.common.pageRowCnt = !parameter.pageRowCnt ? '0' : '1'
      parameter.common.stndKeyList = !parameter.stndKeyList ? null : parameter.stndKeyList
      pParams.data = parameter
    } else if (tagetId === 'txTSSBC16P1') { // EAI 또는 LEGACY 직접연계
      pParams.data = parameter.data // body (조회 Data)
      pParams.common = parameter.common // 조회 서비스 헤더 정보
      pParams.common.useCompress = !parameter.useCompress ? false : parameter.useCompress // 압축 통신
    } // end else if
  } // end else if
  return pParams
}
/************************************************************************************************
 * Function명  : invoke
 * 설명        : 서비스 호출 공통
 * @param parameter : 조회 조건
 * @param resultHandler : 응답 메소드
 * @param moreData : 사용자 정의
 * @param errorHandler :  오류 이벤트
 ************************************************************************************************/
serviceManager.invoke = function (parameter, resultHandler, moreData = null, errorHandler, isShow = false) {
  let pParams = this.setCommServiceData(Object.assign({}, parameter), 'txTSSBC16P1')
  let commHeaderVO = pParams.common
  let context = !pParams.context ? this : pParams.context
  let errorData = '처리 중 오류가 발생했습니다.'
  let errorNoData = '처리 데이타가 없습니다.'
  // let errorE001Data = '[E001] 통신상태가 원활하지 않아 서비스가 지연되고 있습니다.\n잠시 후 다시 시도하세요'
  console.log('---------------------------  serviceManager.invoke  --------------------')
  if (parameter) {
    try {
      window.vue.getInstance('http').post(pParams.context, pParams.data, pParams.tagetId, pParams.btnId, commHeaderVO, isShow)
        .then(function (response) {
          let rtnMsgComm = response.msgComm
          if (response && response.body) {
            let rtnData
            // 전자청약 전용 통신 구분
            if (pParams.tagetId === 'txTSSPS00S1') {
              rtnData = response.body.data
            } else {
              rtnData = response.body
            } // end else if
            if (pParams.appId === 'sli.pqm') {
              rtnData = JSON.parse(rtnData.sdrvDtVal)
            } // end if
            // pParams.common
            // Data 값이 있는지 판단
            if (rtnData !== null) {
              pParams.data = rtnData
              pParams.msgComm = rtnMsgComm
              pParams.trnstComm = response.trnstComm
              resultHandler.call(context, pParams, moreData)
            } else {
              errorHandler.call(context, {msgCd: 'NODATA', msgDesc: errorNoData}, moreData)
            } // end else if
          } else {
            errorHandler.call(context, rtnMsgComm, moreData)
          } // end else if
        }).catch(function (error) {
          errorHandler.call(context, {msgCd: 'ERROR', msgDesc: error.message}, moreData)
        })
    } catch (error) {
      // errorHandler.call(context, {msgCd: 'ERROR', msgDesc: errorData}, moreData)
      errorHandler.call(context, {msgCd: 'ERROR', msgDesc: errorData, msgDescDe: error.stack}, moreData) // msgDescDe: error.stack , ASR200100469 (20200221 운영반영)
    }
  } // end if
}
export default serviceManager
