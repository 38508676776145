/*
 * 업무구분: 활동현황
 * 화 면 명: MSPAP505P
 * 화면설명: 진행조회
 * 접근권한: 지점장
 * 작 성 일: 2022.06.27
 * 작 성 자: 조한결
 */
<template>
  <ur-page-container title="진행조회" :show-title="true" type="popup">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">

        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-active-list ing-sch">
          <mo-list-item expandable ref="expItem" v-show="isContrStateShow">
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <div class="txt_box">
                  <span class="title">계약자</span><b class="name">{{contrNm}}</b>
                </div>
              </div>
            </div>
            <!-- 계약자 -->
            <template v-slot:expand class="ns-gray-box">
              <div class="list-item__contents">
                <div class="active-content">
                  <div :class="[contrPrgPhseCd === '00' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">시작</span> 
                  </div>
                  <div :class="[contrPrgPhseCd === '01' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">필수 동의</span> 
                  </div>
                  <div :class="[contrPrgPhseCd === '02' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">상품설명서 작성</span> 
                  </div>
                  <div :class="[contrPrgPhseCd === '03' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">청약정보확인</span> 
                  </div>
                  <div :class="[contrPrgPhseCd === '04' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">계약전 알릴의무사항</span> 
                  </div>
                  <div :class="[contrPrgPhseCd === '05' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">종료</span> 
                  </div>
                </div>
              </div>
            </template>
          </mo-list-item>

          <!-- 피보험자 -->
          <mo-list-item expandable ref="expItem" v-show="isInsuredStateShow">
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <div class="txt_box">
                  <span class="title">피보험자</span><b class="name">{{insuredNm}}</b>
                </div>
              </div>
            </div>
            <template v-slot:expand class="ns-gray-box">
              <div class="list-item__contents">
                <div class="active-content">
                  <div :class="[insuredPrgPhseCd === '00' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">시작</span> 
                  </div>
                  <div :class="[insuredPrgPhseCd === '01' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">필수 동의</span> 
                  </div>
                  <div :class="[insuredPrgPhseCd === '02' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">상품설명서 작성</span> 
                  </div>
                  <div :class="[insuredPrgPhseCd === '03' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">청약서정보확인</span> 
                  </div>
                  <div :class="[insuredPrgPhseCd === '04' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">계약전 알릴의무사항</span> 
                  </div>
                  <div :class="[insuredPrgPhseCd === '05' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">종료</span> 
                  </div>
                </div>
              </div>
            </template>
          </mo-list-item>

          <!-- 종피보험자 -->
          <mo-list-item expandable ref="expItem" v-show="isAddInsuredStateShow">
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <div class="txt_box">
                  <span class="title">종피보험자</span><b class="name">{{addInsuredNm}}</b>
                </div>
              </div>
            </div>
            <template v-slot:expand class="ns-gray-box">
              <div class="list-item__contents">
                <div class="active-content h75">
                  <div :class="[addInsuredPrgPhseCd === '00' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">시작</span> 
                  </div>
                  <div :class="[addInsuredPrgPhseCd === '01' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">필수 동의</span> 
                  </div>
                  <div :class="[addInsuredPrgPhseCd === '02' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">상품설명서 작성</span> 
                  </div>
                  <div :class="[addInsuredPrgPhseCd === '03' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">청약서정보확인</span> 
                  </div>
                  <div :class="[addInsuredPrgPhseCd === '04' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">계약전 알릴의무사항</span> 
                  </div>
                  <div :class="[addInsuredPrgPhseCd === '05' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">종료</span> 
                  </div>
                </div>
              </div>
            </template>
          </mo-list-item>

          <!-- 예금주 -->
          <mo-list-item expandable ref="expItem" v-show="isDpstrStateShow">
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <div class="txt_box">
                  <span class="title">예금주</span><b class="name">{{dpstrNm}}</b>
                </div>
              </div>
            </div>
            <template v-slot:expand class="ns-gray-box">
              <div class="list-item__contents">
                <div class="active-content h75">
                  <div :class="[dpstrPrgPhseCd === '00' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">시작</span> 
                  </div>
                  <div :class="[dpstrPrgPhseCd === '01' || dpstrPrgPhseCd === '02' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">필수 동의</span> 
                  </div>
                  <div :class="[dpstrPrgPhseCd === '03' || dpstrPrgPhseCd === '04' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">청약서정보확인</span> 
                  </div>
                  <div :class="[dpstrPrgPhseCd === '05' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">종료</span> 
                  </div>
                </div>
              </div>
            </template>
          </mo-list-item>

          <!-- 친권1 -->
          <mo-list-item expandable ref="expItem" v-show="isPrtr1StateShow">
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <div class="txt_box">
                  <span class="title">친권자1</span><b class="name">{{prtr1Nm}}</b>
                </div>
              </div>
            </div>
            <template v-slot:expand class="ns-gray-box">
              <div class="list-item__contents">
                <div class="active-content h75">
                  <div :class="[prtr1PrgPhseCd === '00' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">시작</span> 
                  </div>
                  <div :class="[prtr1PrgPhseCd === '01' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">필수 동의</span> 
                  </div>
                  <div :class="[prtr1PrgPhseCd === '02' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">상품설명서 작성</span> 
                  </div>
                  <div :class="[prtr1PrgPhseCd === '03' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">청약서정보확인</span> 
                  </div>
                  <div :class="[prtr1PrgPhseCd === '04' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">계약전 알릴의무사항</span> 
                  </div>
                  <div :class="[prtr1PrgPhseCd === '05' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">종료</span> 
                  </div>
                </div>
              </div>
            </template>
          </mo-list-item>

          <!-- 친권2 -->
          <mo-list-item expandable ref="expItem" v-show="isPrtr2StateShow">
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <div class="txt_box">
                  <span class="title">친권자2</span><b class="name">{{prtr2Nm}}</b>
                </div>
              </div>
            </div>
            <template v-slot:expand class="ns-gray-box">
              <div class="list-item__contents">
                <div class="active-content h75">
                  <div :class="[prtr2PrgPhseCd === '00' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">시작</span> 
                  </div>
                  <div :class="[prtr2PrgPhseCd === '01' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">필수 동의</span> 
                  </div>
                  <div :class="[prtr2PrgPhseCd === '02' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">상품설명서 작성</span> 
                  </div>
                  <div :class="[prtr2PrgPhseCd === '03' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">청약서정보확인</span> 
                  </div>
                  <div :class="[prtr2PrgPhseCd === '04' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">계약전 알릴의무사항</span> 
                  </div>
                  <div :class="[prtr2PrgPhseCd === '05' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">종료</span> 
                  </div>
                </div>
              </div>
            </template>
          </mo-list-item>

          <!-- FC -->
          <mo-list-item expandable ref="expItem">
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <div class="txt_box">
                  <span class="title">FC</span><b class="name">{{fcNm}}</b>
                </div>
              </div>
            </div>
            <template v-slot:expand class="ns-gray-box">
              <div class="list-item__contents">
                <div class="active-content h50">
                  <div :class="[fcMobpsElstYn === 'N' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">FC 서명 전</span> 
                  </div>
                  <div :class="[fcMobpsElstYn === 'Y' ? 'timeline-item checked' : 'timeline-item']">
                    <span class="list-step">완료</span> 
                  </div>
                </div>
              </div>
            </template>
          </mo-list-item>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import ServiceManager from '@/ui/sp/common/ServiceManager'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPAP505P",
    screenId: "MSPAP505P",
    components: {},
    props: {
      rowPopupData: Object
    },
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.fn_init()
    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPAP505P')
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
      prgPnstaList: [], // 진행상황목록
      // standardAge15: 15, // 기준 나이 14 / 본인서명불가 나이
      standardAge15: 14, // 기준 나이 14 / 본인서명불가 나이
      prcd: '', // 상품코드 (ASR210400763_2021.04.23 PJO)

      // 계약자
      isContrStateShow: false, // 표시여부
      contrNm: '', // 이름
      contrMobslChnlCustId: '', // 모바일채널고객ID
      contrPrgPhseCd: '00', // 진행단계코드
      contrMobpsElstYn: 'N', // 전자서명완료 여부
      contrKakaoSn: '0', // 카카오톡알림발송순번
      contrUnder15: false, // 계약자 15세 미만

      // 피보험자
      isInsuredStateShow: false,
      insuredNm: '',
      insuredMobslChnlCustId: '', // 모바일채널고객ID
      insuredPrgPhseCd: '00',
      insuredMobpsElstYn: 'N',
      insuredKakaoSn: '0',
      insuredUnder15: false, // 피보험자 15세 미만
      insrdCnsntObjYn: 'N', // 피보험자동의대상여부
      insrdCnsntScCd: 'U', // 피보험자동의구분코드

      // 종피보험자
      isAddInsuredStateShow: false,
      addInsuredNm: '',
      addInsuredPrgPhseCd: '00',
      addInsuredMobpsElstYn: 'N',
      addInsuredKakaoSn: '0',

      // 예금주
      isDpstrStateShow: false,
      dpstrNm: '',
      dpstrMobslChnlCustId: '', // 모바일채널고객ID
      dpstrPrgPhseCd: '00',
      dpstrMobpsElstYn: 'N',
      dpstrKakaoSn: '0',

      // 친권1
      isPrtr1StateShow: false,
      prtr1Nm: '',
      prtr1PrgPhseCd: '00',
      prtr1MobpsElstYn: 'N',
      prtr1KakaoSn: '0',

      // 친권2
      isPrtr2StateShow: false,
      prtr2Nm: '',
      prtr2PrgPhseCd: '00',
      prtr2MobpsElstYn: 'N',
      prtr2KakaoSn: '0',

      // FC
      fcNm: this.getStore('userInfo').getters.getUserInfo.userNm,
      fcMobpsElstYn: 'N',
      fcKakaoSn: '0',
    }
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
    /******************************************************************************
    * Function명 : fn_init
    * 설명       : 초기화
    ******************************************************************************/
    fn_init: function () {
      // 계약자
      this.isContrStateShow = true
      this.contrPrgPhseCd = '00' // 계약자진행단계코드

      // FC
      this.fcMobpsElstYn = 'N' // FC 전자서명완료 여부

      // 모바일청약 중단건진행현황조회
      this.fn_ServiceData('S1')
    },
    /******************************************************************************
    * Function명  : fn_ServiceData
    * 설명        : 서비스 호출 함수 정의
    ******************************************************************************/
    fn_ServiceData: function (serviceName) {
      let pParams = {}
      this.isLoadingStatus = true
      let tParams = {}
      switch (serviceName) {
        case 'S1': // 모바일청약 중단건진행현황조회
          tParams.appId = 'tss.ps'
          tParams.trnstId = 'txTSSPS50S3'
          tParams.fnctScCd = 'S'
          tParams.serviceId = ''
          tParams.reqSrvcNm = 'PSMobpsContMngSVCImpl'
          tParams.reqMthdNm = 'selMobpsIntrsCsePrgPnstaList'
          tParams.reqVONm = 'sli.tss.ps.eltrnsign.svc.svo.PSElstIntrsCseReqSVO'
          tParams.resVONm = 'sli.tss.ps.eltrnsign.svc.svo.PSElstIntrsCseListSVO'
          tParams.srnId = '' // 대표 화면 명
          tParams.data = {}
          tParams.data.vuchId = '' // 전자문서ID

          pParams = Object.assign({}, tParams)
          pParams.data = {}
          pParams.data.predVuchId = this.rowPopupData.vuchId // 영수증ID
          break
        case 'S2': // 카카오 알림톡 발송
          tParams.appId = 'tss.ps'
          tParams.trnstId = 'txTSSPS50P2'
          tParams.fnctScCd = 'S'
          tParams.serviceId = ''
          tParams.reqSrvcNm = 'PSKakaoIapTalkSVCImpl'
          tParams.reqMthdNm = 'prcsKaKaoIapTalkSend'
          tParams.reqVONm = 'sli.tss.ps.eltrnsign.svc.svo.PSKakaoIapTalkSVO'
          tParams.resVONm = 'sli.tss.ps.eltrnsign.svc.svo.PSKakaoIapTalkSVO'
          tParams.srnId = '' // 대표 화면 명
          tParams.data = {}
          tParams.data.vuchId = '' // 영수증ID
          tParams.data.mobslChnlCustId = '' // 계약관계인구분코드
          tParams.data.mobslChnlCustNm = '' // 모바일영업채널고객명

          pParams = Object.assign({}, tParams)
          pParams.data = {}
          pParams.data.vuchId = this.rowPopupData.vuchId // 영수증ID
          pParams.data.mobslChnlCustId = this.sendUserInfo.mobslChnlCustId // 모바일영업채널고객ID
          pParams.data.mobslChnlCustNm = this.sendUserInfo.mobslChnlCustNm // 모바일영업채널고객명
          pParams.data.contPtcpScCd = this.sendUserInfo.contPtcpScCd // 계약관계인구분코드
          break
        default:
          break
      } // end switch

      pParams.srnId = this.$options.screenId // 대표 화면 명
      ServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler)
    },
    /************************************************************************************************
     * Function명  : fn_ServiceDataResultHandler
     * 설명        : 서비스 호출 결과 함수 정의
     ************************************************************************************************/
    fn_ServiceResultHandler: function (event, serviceName) {
      let lv_data = event.data

      switch (serviceName) {
        case 'S1': // 모바일청약 중단건진행현황조회
          this.prgPnstaList = lv_data.pSElstIntrsCseListDVO
          this.viewFormUrl = lv_data.viewFormUrl

          if (this.prgPnstaList && this.prgPnstaList.length > 0) {
            this.prgPnstaList.forEach(pIteam => {
              switch (pIteam.contPtcpScCd) {
                case '1': // 계약자
                  this.isContrStateShow = true
                  this.contrNm = pIteam.mobslChnlCustNm // 이름
                  this.contrMobslChnlCustId = pIteam.mobslChnlCustId // 모바일채널고객ID
                  this.contrPrgPhseCd = pIteam.mobpsPrgPhseCd // 진행단계코드
                  this.contrMobpsElstYn = pIteam.mobpsElstYn // 전자서명 완료여부
                  this.contrKakaoSn = pIteam.kakaoIapDspchSn // 카카오톡알림발송순번
                  this.prcd = pIteam.prcd // 상품코드 (ASR210400763_2021.04.23 PJO)

                  // 꿈나무어린이 보험인경우  - 대상상품 : 꿈나무 어린이보험 限(상품코드 앞 여섯자리가 'LP0630'인 경우)
                  // 요청사항 : 계약관계자(계약자 또는 피보험자)가  만19세 미만인 경우  친권자1 이 대리 서명 진행
                  console.log('상품코드:[' + pIteam.prcd + '],standardAge15:' + this.standardAge15)
                  if (!this.$bizUtil.isEmpty(pIteam.prcd) && pIteam.prcd.length >= 6) {
                    if (pIteam.prcd.substr(0, 6) === 'LP0630') {
                      this.standardAge15 = 19
                    }
                  }
                  console.log('상품코드:[' + pIteam.prcd + '],standardAge15:' + this.standardAge15)

                  if ((pIteam.contPtcpRrnEncr.length !== 10 && !this.$bizUtil.compareCurrentAge(pIteam.contPtcpRrnEncr.substr(0, 6), this.standardAge15)) ||
                       pIteam.contPtcpRrnEncr.substr(0, 6) === '000000') { // 태아
                    // 계약자가 15세미만
                    this.contrUnder15 = true
                  }

                  break
                case '2': // 피보험자
                  this.isInsuredStateShow = true
                  this.insuredNm = pIteam.mobslChnlCustNm
                  this.insuredMobslChnlCustId = pIteam.mobslChnlCustId // 모바일채널고객ID
                  this.insuredPrgPhseCd = pIteam.mobpsPrgPhseCd
                  this.insuredMobpsElstYn = pIteam.mobpsElstYn
                  this.insuredKakaoSn = pIteam.kakaoIapDspchSn
                  if (!this.$bizUtil.compareCurrentAge(pIteam.contPtcpRrnEncr.substr(0, 6), this.standardAge15)) {
                    // 피보험자가 15세미만
                    this.insuredUnder15 = true
                  }

                  this.insrdCnsntObjYn = pIteam.insrdCnsntObjYn // 피보험자동의대상여부
                  this.insrdCnsntScCd = pIteam.insrdCnsntScCd // 피보험자동의구분코드

                  break
                case '3': // 종피보험자
                  this.isAddInsuredStateShow = true
                  this.addInsuredNm = pIteam.mobslChnlCustNm
                  this.addInsuredPrgPhseCd = pIteam.mobpsPrgPhseCd
                  this.addInsuredMobpsElstYn = pIteam.mobpsElstYn
                  this.addInsuredKakaoSn = pIteam.kakaoIapDspchSn

                  // 종피보험자가 있는 경우, 지문촬영은 수행할수 없음
                  this.insrdCnsntTypes = this.addInsrdCnsntTypes
                  break
                case '4': // 친권1
                  this.isPrtr1StateShow = true
                  this.prtr1Nm = pIteam.mobslChnlCustNm
                  this.prtr1PrgPhseCd = pIteam.mobpsPrgPhseCd
                  this.prtr1MobpsElstYn = pIteam.mobpsElstYn
                  this.prtr1KakaoSn = pIteam.kakaoIapDspchSn
                  break
                case '5': // 친권2
                  this.isPrtr2StateShow = true
                  this.prtr2Nm = pIteam.mobslChnlCustNm
                  this.prtr2PrgPhseCd = pIteam.mobpsPrgPhseCd
                  this.prtr2MobpsElstYn = pIteam.mobpsElstYn
                  this.prtr2KakaoSn = pIteam.kakaoIapDspchSn
                  break
                case '6': // 예금주
                  this.isDpstrStateShow = true
                  this.dpstrNm = pIteam.mobslChnlCustNm
                  this.dpstrMobslChnlCustId = pIteam.mobslChnlCustId // 모바일채널고객ID
                  this.dpstrPrgPhseCd = pIteam.mobpsPrgPhseCd
                  this.dpstrMobpsElstYn = pIteam.mobpsElstYn
                  this.dpstrKakaoSn = pIteam.kakaoIapDspchSn
                  break
                case '9': // FC
                  this.fcNm = pIteam.mobslChnlCustNm
                  this.fcPrgPhseCd = pIteam.mobpsElstYn
                  this.fcMobpsElstYn = pIteam.mobpsElstYn
                  this.fcKakaoSn = pIteam.kakaoIapDspchSn
                  break
                default:
                  break
              } // switch (pIteam.contPtcpScCd)
            }) // foreach 종료

            // 계피예 동일인경우, 피보험자, 예금주 행 숨김
            // if (this.contrMobslChnlCustId === this.insuredMobslChnlCustId &&
            //     this.insuredMobslChnlCustId === this.dpstrMobslChnlCustId) {
            //   this.isInsuredStateShow = false
            //   this.isDpstrStateShow = false
            // }
          }
          break
        case 'S2': // 카카오 알림톡 발송
          let sendRsltCd = lv_data.sendRsltCd

          if (sendRsltCd === 'E000') {
            this.getStore('confirm').dispatch('ADD', '알림톡이 발송되었습니다.')
          } else if (sendRsltCd === 'E001') {
            this.getStore('confirm').dispatch('ADD', '계약관계자의 연락처가 없습니다.')
          } else if (sendRsltCd === 'E002') {
            this.getStore('confirm').dispatch('ADD', '계약관계자의 서명이 완료되지 않았습니다.')
          }
          break
        default:
          break
      } // switch (serviceName)
    },
    /************************************************************************************************
     * Function명  : fn_ServiceFaultHandler
     * 설명        : 에러 이벤트
     ************************************************************************************************/
    fn_ServiceFaultHandler: function (event, serviceName) {
      this.getStore('confirm').dispatch('ADD', event.msgDesc)
    },
    /************************************************************************************************
     * Function명  : fn_KaKaoSend
     * 설명        : 카카오 알림톡 발송
     ************************************************************************************************/
    fn_KaKaoSend: function (contPtcpScCd) {
      this.prgPnstaList.forEach(pIteam => {
        if (contPtcpScCd === pIteam.contPtcpScCd) {
          this.sendUserInfo = pIteam
        }
      }) // this.prgPnstaList.forEach(pIteam => {

      // 카카오 알림톡 재발송
      this.fn_ServiceData('S2')
    },
    /************************************************************************************************
     * Function명  : fn_openWebViewURL
     * 설명        : 사랑On에서 WebView로 호출
     ************************************************************************************************/
    fn_openWebViewURL: function (contPtcpScCd) {
      this.prgPnstaList.forEach(pIteam => {
        if (contPtcpScCd === pIteam.contPtcpScCd) {
          this.sendUserInfo = pIteam
        }
      }) // this.prgPnstaList.forEach(pIteam => {

      // 카카오 알림톡 재발송
      let serverChoice = process.env.NODE_ENV
      let targetUrl = this.sendUserInfo.mobpsWhlUrlCntnt

      if (this.$commonUtil.isMobile()) {
        window.fdpbridge.exec('PhoneWebViewPlugin', {uri: targetUrl}, () => {}, () => {})
      } else {
        // 운영환경이 아닌 경우 get 방식으로 파라미터 호출
        if (serverChoice !== 'production') {
          window.open(targetUrl, '_blank')
        }
      }
    },
    /************************************************************************************************
     * Function명  : fn_openOzViewer
     * 설명        : 사랑On에서 ozViewer호출
     ************************************************************************************************/
    fn_openOzViewer: function () {
      let isMobile = window.vue.getInstance('vue').$commonUtil.isMobile()
      let serverChoice = process.env.NODE_ENV

      if (isMobile) {
        window.fdpbridge.exec('PhoneWebViewPlugin', {uri: this.viewFormUrl}, () => {}, () => {})
      } else {
        // 운영환경이 아닌 경우 get 방식으로 파라미터 호출
        if (serverChoice !== 'production') {
          window.open(this.viewFormUrl, '_blank')
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_ClickLst
    * 설명       : 리스트 On/Off
    * 인자       : N/A
    * Return     : N/A
    ******************************************************************************/
    fn_ClickLst (num) {
      this.lv_list[num] = !this.lv_list[num]
    },
    /******************************************************************************
    * Function명  : fn_PopClose
    * 설명        : 팝업 종료 함수 정의
    ******************************************************************************/
      fn_PopClose () {
        this.$emit('onPopupConfirm')
      }
    }
  };
</script>
<style scoped>
</style>